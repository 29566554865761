import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faInstagram,
    faTwitch,
    faKickstarter,
    faDiscord
  } from "@fortawesome/free-brands-svg-icons";


export default function SocialFollow() {
  


  return (
    <div class="social-container">
      <a  href="https://www.youtube.com/@TOP1EuSouMais9/"
        className="youtube social">
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      <a  href="https://www.instagram.com/EuSouMais9/"
        className="instagram social">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a href="https://www.twitch.tv/eusoumais9/"
        className="twitch social">
        <FontAwesomeIcon icon={faTwitch} size="2x" />
      </a>
      <a href="https://kick.com/eusoumais9/"
        className="kick social">
        <FontAwesomeIcon icon={faKickstarter} size="2x" />
      </a>
      <a  href="https://discord.gg/xN3Tan42Au"
        className="discord social">
        <FontAwesomeIcon icon={faDiscord} size="2x" />
      </a>
    </div>
  );
}