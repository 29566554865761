import './App.css';
import React from 'react';
import SocialFollow from "./SocialFollow"
import logo from './avatarmais9.png';
import gif from './elveronMais9.gif';

export default function App() {
  const handleGifClick = () => {
    window.location.href = 'https://elveron.to/@eusoumais9';
  };

  return (
    <div className='App'>

      <div className='App-header'>
        <img src={logo} alt="Logo" />
        <h1>EuSouMais9</h1>
        <div>
          <SocialFollow />
        </div>
        <p>Chamo-me Igor, tenho 32 anos e gosto de jogar Metin2 </p>
        <p>___________________________________________________________</p>
        
        <div className='App-elveron' onClick={handleGifClick}>
          <img src={gif} alt="GIF" />
        </div>

        <p></p>
        <p></p>
        <p></p>
        <p></p>
      </div>


    </div>
  );
}
